// Dependencies
import { observable, action } from 'mobx';

// Services
import { customersService } from '../services';

class CustomersStore {
	@observable customers = [];

	getAll = action(async () => {
		const data = await customersService.getAll();
		return (this.customers = data);
	});
}

export default new CustomersStore();
