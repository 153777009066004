// Dependencies
import { observable, action } from 'mobx';

// Services
import { usersService } from '../services';

class UsersStore {
	@observable users = [];

	getAll = action(async () => {
		const data = await usersService.getAll();
		return (this.users = data);
	});
}

export default new UsersStore();
