// Stores
import sessionStore from './sessionStore';
import errorStore from './errorStore';
import messageStore from './messageStore';
import tracksStore from './tracksStore';
import samplesStore from './samplesStore';
import tracksSearchStore from './tracksSearchStore';
import playlistsStore from './playlistsStore';
import usersStore from './usersStore';
import productsStore from './productsStore';
import plansStore from './plansStore';
import queuesStore from './queuesStore';
import customersStore from './customersStore';
import { useStaticRendering } from 'mobx-react';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);

export const stores = {
	sessionStore,
	errorStore,
	messageStore,
	tracksStore,
	tracksSearchStore,
	playlistsStore,
	usersStore,
	productsStore,
	plansStore,
	queuesStore,
	customersStore,
	samplesStore,
};
