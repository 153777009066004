// Dependencies
import { observable, action } from 'mobx';

// Services
import { queuesService } from '../services';

class QueuesStore {
	@observable queues = [];

	getAll = action(async () => {
		const data = await queuesService.getAll();
		return (this.queues = data);
	});
}

export default new QueuesStore();
