// Dependencies
import { observable, action } from 'mobx';

// Services
import { plansService } from '../services';

class PlansStore {
	@observable plans = [];

	getAll = action(async () => {
		const data = await plansService.getAll();
		return (this.plans = data);
	});
}

export default new PlansStore();
