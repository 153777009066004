// Dependencies
import { observable, action } from 'mobx';

// Services
import { playlistsService } from '../services';

class PlaylistsStore {
	@observable playlists = [];

	create = action(async (body) => {
		const playlist = await playlistsService.create(body);
		// Creating a new playlist triggers a change in the list of playlists,
		// so just do a refresh for now
		await this.getAll();
		return playlist;
	});

	get = action(async (id) => {
		const data = await playlistsService.get(id);
		return data;
	});

	getAll = action(async () => {
		const data = await playlistsService.getAll();
		return (this.playlists = data);
	});

	update = action(async (body) => {
		const playlist = await playlistsService.update(body);
		await this.getAll();
		return playlist;
	});

	delete = action(async (id) => {
		await playlistsService.delete({ id });
		await this.getAll();
		return;
	});
}

export default new PlaylistsStore();
