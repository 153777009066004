import React, { Component } from 'react';
import { stores } from '../src/stores';
import { Provider } from 'mobx-react';
import Head from 'next/head';
import { Theme } from '@anephenix/ui';
import { env, sentryDSN } from '../src/config';

import '../styles/index.scss';
import '../styles/navbar.scss';

import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: sentryDSN,
	environment: env,
});

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { eventId: null, hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<button
					onClick={() =>
						Sentry.showReportDialog({ eventId: this.state.eventId })
					}
				>
					Report feedback
				</button>
			);
		}
		return this.props.children;
	}
}

function MyApp({ Component, pageProps }) {
	return (
		<ErrorBoundary>
			<Provider {...stores}>
				<Head>
					<meta
						name="viewport"
						content="initial-scale=1.0, width=device-width"
					/>
					<title>Dan Blaax Admin</title>
				</Head>
				<Theme>
					<div id="background" />
					<div className="page">
						<Component {...pageProps} />
					</div>
				</Theme>
			</Provider>
		</ErrorBoundary>
	);
}

export default MyApp;
