// Dependencies
import { observable, action } from 'mobx';

// Services
import { tracksService } from '../services';

class TracksStore {
	@observable tracks = [];

	create = action(async (body) => {
		await tracksService.create(body);
		// Creating a new track triggers a change in the list of tracks,
		// so just do a refresh for now
		await this.getAll();
	});

	get = action(async (id) => {
		const data = await tracksService.get(id);
		return data;
	});

	getAll = action(async () => {
		const data = await tracksService.getAll();
		return (this.tracks = data);
	});
}

export default new TracksStore();
