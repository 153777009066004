// Dependencies
import { observable, action } from 'mobx';

// Services
import { tracksService, playlistTrackJoinsService } from '../services';

class TracksSearchStore {
	@observable results = [];
	@observable searchPerformed = false;

	search = action(async (body) => {
		const data = await tracksService.search(body);
		this.searchPerformed = true;
		return (this.results = data);
	});

	addTrackToPlaylist = action(async ({ track_id, playlist_id, order }) => {
		await playlistTrackJoinsService.create({
			track_id,
			playlist_id,
			order,
		});
	});

	reset = action(() => {
		this.searchPerformed = false;
		this.results = [];
	});
}

export default new TracksSearchStore();
