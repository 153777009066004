// Dependencies
import { observable, action } from 'mobx';

class MessageStore {
	@observable message = null;

	handle = action(message => {
		this.message = message;
	});

	clear = action(() => {
		this.message = null;
	});
}

export default new MessageStore();
