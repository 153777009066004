// Dependencies
import { observable, action } from 'mobx';

// Services
import { samplesService } from '../services';

class SamplesStore {
	@observable samples = [];

	create = action(async (body) => {
		await samplesService.create(body);
		// Creating a new sample triggers a change in the list of samples,
		// so just do a refresh for now
		await this.getAll();
	});

	get = action(async (id) => {
		const data = await samplesService.get(id);
		return data;
	});

	getAll = action(async () => {
		const data = await samplesService.getAll();
		return (this.samples = data);
	});
}

export default new SamplesStore();
