// Dependencies
import { observable, action } from 'mobx';

// Services
import { productsService } from '../services';

class ProductsStore {
	@observable products = [];

	getAll = action(async () => {
		const data = await productsService.getAll();
		return (this.products = data);
	});
}

export default new ProductsStore();
